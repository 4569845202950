<template>
  <k-form-element>
    <div
      v-if="kendoForm.visited && kendoForm.touched && kendoForm.errors.code"
      class="row col-12 error"
    >
      <span><icon-warning /></span>
      <span v-text="$t(kendoForm.errors.code)"></span>
    </div>
    <div class="row">
      <div class="col-12">
        <k-field
          :name="'code'"
          type="text"
          autofocus="autofocus"
          :max-length="'6'"
          :component="'myTemplate'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              id="code"
              class="ta-input--mfa"
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <k-button type="button" fill-mode="link" @click="resendCode"
          >Resend Code</k-button
        >
        <k-button
          type="submit"
          :disabled="!kendoForm.allowSubmit"
          theme-color="primary"
        >
          Login
        </k-button>
      </div>
    </div>
  </k-form-element>
</template>

<script>
export default {
  inject: {
    kendoForm: { default: {} }
  },
  mounted() {
    document.getElementById('code').focus();
  },
  methods: {
    resendCode() {
      this.kendoForm.onFormReset();
      this.$emit('resendCode');
    }
  }
};
</script>
