<template>
	<div class="layout layout--public">
		<div class="layout__body">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<icon-logo />
					</div>
				</div>
				<k-form v-if="!isSuccess" ref="form" :validator="resetPasswordFormValidator" @submit="changePassword">
					<create-password-form />
				</k-form>
				<template v-else>
					<div class="row my-3">
						<div class="col-12 mb-3">
							<span class="k-icon k-i-check-circle k-icon-lg text-success"></span>
						</div>
						<div class="col-12">
							<p class="mb-3">Your account password has been successfully updated.</p>
							<p>Please login to transparently.AI with your new password.</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-end">
							<k-button class="text-end" @click="$router.push('/')" theme-color="primary"> Return to Login </k-button>
						</div>
					</div>
				</template>
			</div>
		</div>
		<k-notification ref="popupNotification" :allow-hide-after="1000" :auto-hide-after="7000" :button="true" :hide-on-click="true" class="mt-2"> </k-notification>
	</div>
</template>

<script>
import createPasswordForm from "./createPasswordForm";
import { resetPasswordFormValidator } from "@/plugins/validator";
import axios from "axios";
export default {
	components: {
		createPasswordForm
	},
	data: function () {
		return {
			isSuccess: false,
			resetPasswordFormValidator
		};
	},
	mounted() {
		this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();
	},
	methods: {
		changePassword(dataItem) {
			if (this.$refs.form.isValid()) {
				const vm = this;
				axios
					.post("/auth/changePassword", {
						idToken: this.$store.state.principal.idToken,
						password: dataItem.newPassword,
						returnSecureToken: true,
						tenantId: this.$store.state.principal.tenantId
					})
					.then((response) => {
						if (response.status == 200) {
							this.isSuccess = true;
							this.email = dataItem.email;
						} else if (response.status == 204) {
							vm.popupNotificationWidget.show("Passwords need to be : At least 8 characters and at most 128 characters, at least 1 lowercase (a-z), at least 1 uppercase (A-Z), at least one digit (0-9), and at least 1 special character (punctuation)", "error");
						}
					});
			}
		}
	}
};
</script>