import { getter } from '@progress/kendo-vue-common';

const emailRegex = new RegExp(
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+([a-zA-Z]){2,7}$/
);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const sixDigitRegex = new RegExp(/\w{6}/);
const alphanumericRegex = new RegExp(/^[a-zA-Z0-9-]+$/);

export const termsValidator = (value) =>
  value ? '' : "It's required to agree with Terms and Conditions.";

export const emailValidator = (value) =>
  !value
    ? 'Email field is required.'
    : emailRegex.test(value)
    ? ''
    : 'Email is not in a valid format.';

export const nameValidator = (value) =>
  !value
    ? 'Full Name is required'
    : value.length < 7
    ? 'Full Name should be at least 7 characters long.'
    : '';

export const cardValidator = (value) =>
  !value
    ? 'Credit card number is required. '
    : ccardRegex.test(value)
    ? ''
    : 'Not a valid credit card number format.';

export const cvcValidator = (value) =>
  !value
    ? 'CVC code is required,'
    : cvcRegex.test(value) || value.length !== 3
    ? ''
    : 'Not a valid CVC code format.';

export const requiredValidator = (value) =>
  value ? '' : 'Error: This field is required.';

export const passwordValidator = (value) =>
  value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';

export const addressValidator = (value) =>
  value ? '' : 'Address is required.';

export const regionValidator = (value) => (value ? '' : 'Region is required.');

const emailGetter = getter('email');
const passwordGetter = getter('password');
const newPasswordGetter = getter('newPassword');
const confirmPasswordGetter = getter('confirmPassword');
const codeGetter = getter('code');
// const uidGetter = getter('uid');
const displayNameGetter = getter('displayName');
// const statusGetter = getter('status');
const organizationNameGetter = getter('organizationName');
const organizationUrlGetter = getter('organizationUrl');
const contactNameGetter = getter('contactName');
const contactEmailGetter = getter('contactEmail');
const startDateGetter = getter('startDate');
const endDateGetter = getter('endDate');
const regionTenantGetter = getter('region');

export const loginFormValidator = (values) => {
  const email = emailGetter(values);
  const password = passwordGetter(values);

  if (email && emailRegex.test(email) && password) {
    return {};
  }

  return {
    summary: 'EMAIL_PASSWORD_EMPTY',
    ['email']: !email
      ? 'EMAIL_PASSWORD_EMPTY'
      : emailRegex.test(email)
      ? ''
      : 'INVALID_EMAIL_FORMAT',
    ['password']: !password ? 'EMAIL_PASSWORD_EMPTY' : ''
  };
};

export const forgotPasswordFormValidator = (values) => {
  const email = emailGetter(values);

  if (email && emailRegex.test(email)) {
    return {};
  }

  return {
    ['email']: !email
      ? 'EMPTY_EMAIL'
      : emailRegex.test(email)
      ? ''
      : 'INVALID_EMAIL_FORMAT'
  };
};

export const resetPasswordFormValidator = (values) => {
  const newPassword = newPasswordGetter(values);
  const confirmPassword = confirmPasswordGetter(values);

  if (newPassword && confirmPassword && newPassword == confirmPassword) {
    return;
  }

  return {
    summary: 'EMPTY_PASSWORD',
    ['newPassword']: !newPassword ? 'EMPTY_PASSWORD' : '',
    ['confirmPassword']: !confirmPassword
      ? 'EMPTY_PASSWORD'
      : newPassword == confirmPassword
      ? ''
      : 'PASSWORD_NOT_MATCH'
  };
};

export const mfaCodeValidator = (values) => {
  const code = codeGetter(values);

  if (code && sixDigitRegex.test(code)) {
    return {};
  }

  return {
    ['code']: !code
      ? 'EMPTY_OTP_CODE'
      : sixDigitRegex.test(code)
      ? ''
      : 'EMPTY_OTP_CODE'
  };
};

export const userFormValidator = (values) => {
  // const uid = uidGetter(values);
  const displayName = displayNameGetter(values);
  const email = emailGetter(values);
  // const status = statusGetter(values);

  if (displayName && email && emailRegex.test(email) && status) {
    return {};
  }

  return {
    ['displayName']: !displayName ? 'User Name is required.' : '',
    ['email']: !email
      ? 'Email is required.'
      : emailRegex.test(email)
      ? ''
      : 'Please enter a valid email.'
  };
};

class TenantFormBuilder {
  validator = {};

  constructor(values) {
    this.organizationName = organizationNameGetter(values);
    this.organizationUrl = organizationUrlGetter(values);
    this.contactName = contactNameGetter(values);
    this.contactEmail = contactEmailGetter(values);
    this.startDate = startDateGetter(values);
    this.endDate = endDateGetter(values);
    this.region = regionTenantGetter(values);
  }

  setRegion() {
    this.validator.region = !this.region ? 'Region is required.' : '';
    return this;
  }

  setOrganizationName() {
    if (!this.organizationName) {
      this.validator.organizationName = 'Organization Name is required.';
      return this;
    }

    if (alphanumericRegex.test(this.organizationName)) {
      this.validator.organizationName = '';
      return this;
    }

    this.validator.organizationName = 'INVALID_DISPLAY_NAME';
    return this;
  }

  setOrganizationUrl() {
    this.validator.organizationUrl = !this.organizationUrl
      ? 'Organization URL is required.'
      : '';
    return this;
  }

  setContactName() {
    this.validator.contactName = !this.contactName
      ? 'Contact Name is required.'
      : '';
    return this;
  }

  setContactEmail() {
    if (!this.contactEmail) {
      this.validator.contactEmail = 'Contact Email is required.';
      return this;
    }

    if (emailRegex.test(this.contactEmail)) {
      this.validator.contactEmail = '';
      return this;
    }

    this.validator.contactEmail = 'Please enter a valid email.';
    return this;
  }

  setStartDate() {
    this.validator.startDate = !this.startDate ? 'Start Date is required.' : '';
    return this;
  }

  setEndDate() {
    if (!this.endDate) {
      this.endDate.endDate = 'End Date is required.';
      return this;
    }

    if (new Date(this.startDate) <= new Date(this.endDate)) {
      this.endDate.endDate = '';
      return this;
    }

    this.endDate.endDate = 'End Date must greater than Start Access required.';
    return this;
  }

  build() {
    return this.validator;
  }
}
export const tenantFormValidator = (values) => {
  const organizationName = organizationNameGetter(values);
  const organizationUrl = organizationUrlGetter(values);
  const contactName = contactNameGetter(values);
  const contactEmail = contactEmailGetter(values);
  const startDate = startDateGetter(values);
  const endDate = endDateGetter(values);
  const region = regionTenantGetter(values);

  if (
    region &&
    organizationName &&
    organizationUrl &&
    contactName &&
    contactEmail &&
    emailRegex.test(contactEmail) &&
    startDate &&
    endDate &&
    new Date(startDate) <= new Date(endDate)
  ) {
    return {};
  }

  return new TenantFormBuilder()
    .setRegion()
    .setOrganizationName()
    .setOrganizationUrl()
    .setContactName()
    .setContactEmail()
    .setStartDate()
    .setEndDate()
    .build();
};

const keyGetter = getter('key');
const valueGetter = getter('value');

export const settingFormValidator = (values) => {
  const settingKey = keyGetter(values);
  const settingValue = valueGetter(values);

  if (settingKey && settingValue) {
    return {};
  }

  return {
    ['settingKey']: !settingKey ? 'Setting Key is required.' : '',
    ['settingValue']: !settingValue ? 'Setting Value is required.' : ''
  };
};

const ticketTitleGetter = getter('ticketTitle');
const ticketBodyGetter = getter('ticketBody');

export const createIssueTicketValidator = (values) => {
  const ticketTitle = ticketTitleGetter(values);
  const ticketBody = ticketBodyGetter(values);

  if (ticketTitle && ticketBody) {
    return {};
  }

  return {
    summary: "Please enter ticket's title and body. Both are required.",
    ['ticketTitle']: !ticketTitle ? 'Ticket Title is required.' : '',
    ['ticketBody']: !ticketBody ? 'Ticket Body is required.' : ''
  };
};
